import { baseApi as api } from '../../store/api/baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    metricsControllerWebhook: build.query<
      MetricsControllerWebhookApiResponse,
      MetricsControllerWebhookApiArg
    >({
      query: () => ({ url: `/metrics` }),
    }),
    refreshTokensControllerRefreshTokenUser: build.mutation<
      RefreshTokensControllerRefreshTokenUserApiResponse,
      RefreshTokensControllerRefreshTokenUserApiArg
    >({
      query: () => ({ url: `/v1/user/refresh-token`, method: 'POST' }),
    }),
    refreshTokensControllerRefreshTokenAdmin: build.mutation<
      RefreshTokensControllerRefreshTokenAdminApiResponse,
      RefreshTokensControllerRefreshTokenAdminApiArg
    >({
      query: () => ({ url: `/v1/admin/refresh-token`, method: 'POST' }),
    }),
    signInControllerSignIn: build.mutation<
      SignInControllerSignInApiResponse,
      SignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/sign-in`,
        method: 'POST',
        body: queryArg.signInRequestDto,
      }),
    }),
    adminSignInControllerSignIn: build.mutation<
      AdminSignInControllerSignInApiResponse,
      AdminSignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/sign-in`,
        method: 'POST',
        body: queryArg.adminSignInRequestDto,
      }),
    }),
    forgotPasswordControllerForgotPassword: build.mutation<
      ForgotPasswordControllerForgotPasswordApiResponse,
      ForgotPasswordControllerForgotPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/forgot-password`,
        method: 'POST',
        body: queryArg.forgotPasswordRequestDto,
      }),
    }),
    resetPasswordControllerResetPassword: build.mutation<
      ResetPasswordControllerResetPasswordApiResponse,
      ResetPasswordControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/reset-password`,
        method: 'POST',
        body: queryArg.resetPasswordRequestDto,
      }),
    }),
    userMeControllerUserMe: build.query<
      UserMeControllerUserMeApiResponse,
      UserMeControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/users/me` }),
    }),
    getUsersListControllerGetUsersList: build.query<
      GetUsersListControllerGetUsersListApiResponse,
      GetUsersListControllerGetUsersListApiArg
    >({
      query: (queryArg) => ({ url: `/v1/companies/${queryArg.id}/users-list` }),
    }),
    editUserControllerEditUser: build.mutation<
      EditUserControllerEditUserApiResponse,
      EditUserControllerEditUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editUserRequestDto,
      }),
    }),
    adminMeControllerAdminMe: build.query<
      AdminMeControllerAdminMeApiResponse,
      AdminMeControllerAdminMeApiArg
    >({
      query: () => ({ url: `/v1/admins/me` }),
    }),
    outboundTestCallToWebControllerUserMe: build.mutation<
      OutboundTestCallToWebControllerUserMeApiResponse,
      OutboundTestCallToWebControllerUserMeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-call-to-web`,
        method: 'POST',
        body: queryArg.outboundTestCallToWebV2RequestDto,
      }),
    }),
    hangupTestCallOnWebControllerHangup: build.mutation<
      HangupTestCallOnWebControllerHangupApiResponse,
      HangupTestCallOnWebControllerHangupApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-web`, method: 'POST' }),
    }),
    outboundTestCallToPhoneControllerOutboundCall: build.mutation<
      OutboundTestCallToPhoneControllerOutboundCallApiResponse,
      OutboundTestCallToPhoneControllerOutboundCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-call-to-phone`,
        method: 'POST',
        body: queryArg.outboundTestCallToPhoneRequestDto,
      }),
    }),
    getOutboundTestCallToPhoneInfoControllerGetInfo: build.query<
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse,
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/outbound-call-to-phone` }),
    }),
    startConversationRecordControllerStartConversationRecord: build.mutation<
      StartConversationRecordControllerStartConversationRecordApiResponse,
      StartConversationRecordControllerStartConversationRecordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/conversation/start-record`,
        method: 'POST',
        body: queryArg.startConversationRecordRequestDto,
      }),
    }),
    hangupTestCallOnPhoneControllerUserMe: build.mutation<
      HangupTestCallOnPhoneControllerUserMeApiResponse,
      HangupTestCallOnPhoneControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-phone`, method: 'POST' }),
    }),
    loadTestAgentsControllerLoadTestAgents: build.mutation<
      LoadTestAgentsControllerLoadTestAgentsApiResponse,
      LoadTestAgentsControllerLoadTestAgentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/conversation/load-test-agents`,
        method: 'POST',
        body: queryArg.loadTestAgentsRequestDto,
      }),
    }),
    outboundMultipleCallsToPhoneControllerOutboundCalls: build.mutation<
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse,
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-multiple-calls-to-phone`,
        method: 'POST',
        body: queryArg.outboundMultipleCallsToPhoneRequestDto,
      }),
    }),
    createCampaignControllerCreateCampaign: build.mutation<
      CreateCampaignControllerCreateCampaignApiResponse,
      CreateCampaignControllerCreateCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign`,
        method: 'POST',
        body: queryArg.createCampaignRequestDto,
      }),
    }),
    createCampaignRunControllerCreateCampaignRun: build.mutation<
      CreateCampaignRunControllerCreateCampaignRunApiResponse,
      CreateCampaignRunControllerCreateCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/run`, method: 'POST' }),
    }),
    createRecipientConversationSentimentControllerCreateRecipientConversationSentiment:
      build.mutation<
        CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiResponse,
        CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/recipient-conversation/${queryArg.id}/add-sentiment`,
          method: 'POST',
          body: queryArg.recipientConversationSentimentRequestDto,
          headers: { 'conversation-type': queryArg['conversation-type'] },
        }),
      }),
    deleteCampaignControllerDeleteCampaign: build.mutation<
      DeleteCampaignControllerDeleteCampaignApiResponse,
      DeleteCampaignControllerDeleteCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}`, method: 'DELETE' }),
    }),
    editCampaignControllerEditCampaign: build.mutation<
      EditCampaignControllerEditCampaignApiResponse,
      EditCampaignControllerEditCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editCampaignRequestDto,
      }),
    }),
    getCampaignControllerGetCampaign: build.query<
      GetCampaignControllerGetCampaignApiResponse,
      GetCampaignControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}` }),
    }),
    deleteLeadListControllerDeleteLeadList: build.mutation<
      DeleteLeadListControllerDeleteLeadListApiResponse,
      DeleteLeadListControllerDeleteLeadListApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/lead-list/${queryArg.id}`, method: 'DELETE' }),
    }),
    finishingCampaignControllerFinishingCampaigns: build.mutation<
      FinishingCampaignControllerFinishingCampaignsApiResponse,
      FinishingCampaignControllerFinishingCampaignsApiArg
    >({
      query: () => ({ url: `/v1/finishing-campaign`, method: 'POST' }),
    }),
    forceRunRecipientsControllerForceRunRecipients: build.mutation<
      ForceRunRecipientsControllerForceRunRecipientsApiResponse,
      ForceRunRecipientsControllerForceRunRecipientsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/campaign/force-run-recipients`,
        method: 'POST',
        body: queryArg.forceRunRecipientsRequestDto,
      }),
    }),
    getAllCampaignsControllerGetAllCampaigns: build.query<
      GetAllCampaignsControllerGetAllCampaignsApiResponse,
      GetAllCampaignsControllerGetAllCampaignsApiArg
    >({
      query: () => ({ url: `/v1/campaigns` }),
    }),
    getCampaignRunControllerGetCampaign: build.query<
      GetCampaignRunControllerGetCampaignApiResponse,
      GetCampaignRunControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/active-campaign-run` }),
    }),
    getMedianCallDurationControllerGetCampaign: build.query<
      GetMedianCallDurationControllerGetCampaignApiResponse,
      GetMedianCallDurationControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/median-call-duration` }),
    }),
    getCallsReportControllerGetCallsReport: build.query<
      GetCallsReportControllerGetCallsReportApiResponse,
      GetCallsReportControllerGetCallsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/get-calls-report`,
        params: {
          campaignId: queryArg.campaignId,
          companyId: queryArg.companyId,
          start: queryArg.start,
          end: queryArg.end,
        },
      }),
    }),
    getRecipientConversationControllerGetCampaign: build.query<
      GetRecipientConversationControllerGetCampaignApiResponse,
      GetRecipientConversationControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/${queryArg.id}/recipient-conversations`,
        params: {
          statuses: queryArg.statuses,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    partialUpdateRecipientConversationControllerPartialUpdateRecipientConversation: build.mutation<
      PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiResponse,
      PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/recipient-conversation/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.partialUpdateRecipientConversationRequestDto,
        headers: { 'conversation-type': queryArg['conversation-type'] },
      }),
    }),
    processCampaignControllerProcessCampaign: build.mutation<
      ProcessCampaignControllerProcessCampaignApiResponse,
      ProcessCampaignControllerProcessCampaignApiArg
    >({
      query: () => ({ url: `/v1/process-recipients`, method: 'POST' }),
    }),
    processFollowUpControllerProcessCampaigns: build.mutation<
      ProcessFollowUpControllerProcessCampaignsApiResponse,
      ProcessFollowUpControllerProcessCampaignsApiArg
    >({
      query: () => ({ url: `/v1/process-follow-up`, method: 'POST' }),
    }),
    processMessageResultControllerProcessMessageResult: build.mutation<
      ProcessMessageResultControllerProcessMessageResultApiResponse,
      ProcessMessageResultControllerProcessMessageResultApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/process-message-result`,
        method: 'POST',
        body: queryArg.processMessageResultRequestDto,
        params: { token: queryArg.token },
      }),
    }),
    stopCampaignRunControllerStopCampaignRun: build.mutation<
      StopCampaignRunControllerStopCampaignRunApiResponse,
      StopCampaignRunControllerStopCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/stop`, method: 'POST' }),
    }),
    terminateConversationControllerTerminateConversation: build.mutation<
      TerminateConversationControllerTerminateConversationApiResponse,
      TerminateConversationControllerTerminateConversationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/recipient-conversation/${queryArg.id}/terminate`,
        method: 'POST',
      }),
    }),
    updateLeadListOrderControllerUpdateLeadListOrder: build.mutation<
      UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse,
      UpdateLeadListOrderControllerUpdateLeadListOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/lead-list/order`,
        method: 'PATCH',
        body: queryArg.updateLeadListOrderRequestDto,
      }),
    }),
    getAgentControllerGetAgent: build.query<
      GetAgentControllerGetAgentApiResponse,
      GetAgentControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}` }),
    }),
    editAgentControllerEditAgent: build.mutation<
      EditAgentControllerEditAgentApiResponse,
      EditAgentControllerEditAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editAgentRequestDto,
      }),
    }),
    deleteAgentControllerDeleteAgent: build.mutation<
      DeleteAgentControllerDeleteAgentApiResponse,
      DeleteAgentControllerDeleteAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}`, method: 'DELETE' }),
    }),
    getAgentSystemControllerGetAgent: build.query<
      GetAgentSystemControllerGetAgentApiResponse,
      GetAgentSystemControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/system/agents/${queryArg.id}` }),
    }),
    getAllAgentsControllerGetAllAgents: build.query<
      GetAllAgentsControllerGetAllAgentsApiResponse,
      GetAllAgentsControllerGetAllAgentsApiArg
    >({
      query: () => ({ url: `/v1/agents` }),
    }),
    createAgentControllerCreateAgent: build.mutation<
      CreateAgentControllerCreateAgentApiResponse,
      CreateAgentControllerCreateAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents`,
        method: 'POST',
        body: queryArg.createAgentRequestDto,
      }),
    }),
    getAllVoicesControllerGetAllVoices: build.query<
      GetAllVoicesControllerGetAllVoicesApiResponse,
      GetAllVoicesControllerGetAllVoicesApiArg
    >({
      query: () => ({ url: `/v1/voices` }),
    }),
    getUsageControllerGetUsage: build.query<
      GetUsageControllerGetUsageApiResponse,
      GetUsageControllerGetUsageApiArg
    >({
      query: () => ({ url: `/v1/admin/model-usage` }),
    }),
    getVoiceControllerGetVoice: build.query<
      GetVoiceControllerGetVoiceApiResponse,
      GetVoiceControllerGetVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voices/${queryArg.id}` }),
    }),
    editVoiceControllerEditVoice: build.mutation<
      EditVoiceControllerEditVoiceApiResponse,
      EditVoiceControllerEditVoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/voices/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voiceRequestDto,
      }),
    }),
    createVoiceControllerCreateVoice: build.mutation<
      CreateVoiceControllerCreateVoiceApiResponse,
      CreateVoiceControllerCreateVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voice`, method: 'POST', body: queryArg.voiceRequestDto }),
    }),
    deleteVoiceControllerDeleteVoice: build.mutation<
      DeleteVoiceControllerDeleteVoiceApiResponse,
      DeleteVoiceControllerDeleteVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voice/${queryArg.id}`, method: 'DELETE' }),
    }),
    createCompanyControllerCreateCompany: build.mutation<
      CreateCompanyControllerCreateCompanyApiResponse,
      CreateCompanyControllerCreateCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/company`,
        method: 'POST',
        body: queryArg.createCompanyRequestDto,
      }),
    }),
    getAllCompaniesControllerGetAllCompanies: build.query<
      GetAllCompaniesControllerGetAllCompaniesApiResponse,
      GetAllCompaniesControllerGetAllCompaniesApiArg
    >({
      query: () => ({ url: `/v1/companies` }),
    }),
    getCompanyControllerGetCompany: build.query<
      GetCompanyControllerGetCompanyApiResponse,
      GetCompanyControllerGetCompanyApiArg
    >({
      query: (queryArg) => ({ url: `/v1/companies/${queryArg.id}` }),
    }),
    editCompanyControllerEditCompany: build.mutation<
      EditCompanyControllerEditCompanyApiResponse,
      EditCompanyControllerEditCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/company/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editCompanyRequestDto,
      }),
    }),
    healthControllerCheck: build.query<
      HealthControllerCheckApiResponse,
      HealthControllerCheckApiArg
    >({
      query: () => ({ url: `/health` }),
    }),
    textToSpeechControllerCreateCampaign: build.mutation<
      TextToSpeechControllerCreateCampaignApiResponse,
      TextToSpeechControllerCreateCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/voice-ai/text-to-speech`,
        method: 'POST',
        body: queryArg.textToSpeechRequestDto,
        params: { enhance: queryArg.enhance, debug: queryArg.debug },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiService };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type MetricsControllerWebhookApiResponse = unknown;
export type MetricsControllerWebhookApiArg = void;
export type RefreshTokensControllerRefreshTokenUserApiResponse =
  /** status 201  */ TokenResponseDto;
export type RefreshTokensControllerRefreshTokenUserApiArg = void;
export type RefreshTokensControllerRefreshTokenAdminApiResponse =
  /** status 201  */ TokenResponseDto;
export type RefreshTokensControllerRefreshTokenAdminApiArg = void;
export type SignInControllerSignInApiResponse = /** status 201  */ TokenResponseDto;
export type SignInControllerSignInApiArg = {
  signInRequestDto: SignInRequestDto;
};
export type AdminSignInControllerSignInApiResponse = /** status 201  */ AdminSignInResponseDto;
export type AdminSignInControllerSignInApiArg = {
  adminSignInRequestDto: AdminSignInRequestDto;
};
export type ForgotPasswordControllerForgotPasswordApiResponse =
  /** status 201  */ ForgotPasswordResponseDto;
export type ForgotPasswordControllerForgotPasswordApiArg = {
  forgotPasswordRequestDto: ForgotPasswordRequestDto;
};
export type ResetPasswordControllerResetPasswordApiResponse =
  /** status 201  */ ResetPasswordResponseDto;
export type ResetPasswordControllerResetPasswordApiArg = {
  resetPasswordRequestDto: ResetPasswordRequestDto;
};
export type UserMeControllerUserMeApiResponse = /** status 200  */ UserDto;
export type UserMeControllerUserMeApiArg = void;
export type GetUsersListControllerGetUsersListApiResponse = /** status 200  */ UserDto[];
export type GetUsersListControllerGetUsersListApiArg = {
  id: string;
};
export type EditUserControllerEditUserApiResponse = /** status 200  */ UserDto;
export type EditUserControllerEditUserApiArg = {
  id: string;
  editUserRequestDto: EditUserRequestDto;
};
export type AdminMeControllerAdminMeApiResponse = /** status 200  */ AdminDto;
export type AdminMeControllerAdminMeApiArg = void;
export type OutboundTestCallToWebControllerUserMeApiResponse =
  /** status 201  */ OutboundTestCallToWebV2ResponseDto;
export type OutboundTestCallToWebControllerUserMeApiArg = {
  outboundTestCallToWebV2RequestDto: OutboundTestCallToWebV2RequestDto;
};
export type HangupTestCallOnWebControllerHangupApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnWebControllerHangupApiArg = void;
export type OutboundTestCallToPhoneControllerOutboundCallApiResponse =
  /** status 201  */ ConversationInfoDto;
export type OutboundTestCallToPhoneControllerOutboundCallApiArg = {
  outboundTestCallToPhoneRequestDto: OutboundTestCallToPhoneRequestDto;
};
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse =
  /** status 200  */ ConversationInfoDto;
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg = void;
export type StartConversationRecordControllerStartConversationRecordApiResponse =
  /** status 201  */ StartConversationRecordResponseDto;
export type StartConversationRecordControllerStartConversationRecordApiArg = {
  startConversationRecordRequestDto: StartConversationRecordRequestDto;
};
export type HangupTestCallOnPhoneControllerUserMeApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnPhoneControllerUserMeApiArg = void;
export type LoadTestAgentsControllerLoadTestAgentsApiResponse =
  /** status 201  */ LoadTestAgentsResponseDto;
export type LoadTestAgentsControllerLoadTestAgentsApiArg = {
  loadTestAgentsRequestDto: LoadTestAgentsRequestDto;
};
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse =
  /** status 201  */ ConversationInfoDto[];
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg = {
  outboundMultipleCallsToPhoneRequestDto: OutboundMultipleCallsToPhoneRequestDto;
};
export type CreateCampaignControllerCreateCampaignApiResponse = /** status 201  */ CampaignDto;
export type CreateCampaignControllerCreateCampaignApiArg = {
  createCampaignRequestDto: CreateCampaignRequestDto;
};
export type CreateCampaignRunControllerCreateCampaignRunApiResponse =
  /** status 201  */ CampaignRunDto;
export type CreateCampaignRunControllerCreateCampaignRunApiArg = {
  id: string;
};
export type CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiResponse =
  /** status 201  */ RecipientConversationSentimentDto;
export type CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiArg =
  {
    id: string;
    /** Conversation type */
    'conversation-type'?: 'outbound-campaign-call' | 'test-call';
    recipientConversationSentimentRequestDto: RecipientConversationSentimentRequestDto;
  };
export type DeleteCampaignControllerDeleteCampaignApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type DeleteCampaignControllerDeleteCampaignApiArg = {
  id: string;
};
export type EditCampaignControllerEditCampaignApiResponse = /** status 200  */ CampaignDto;
export type EditCampaignControllerEditCampaignApiArg = {
  id: string;
  editCampaignRequestDto: EditCampaignRequestDto;
};
export type GetCampaignControllerGetCampaignApiResponse = /** status 200  */ CampaignDto;
export type GetCampaignControllerGetCampaignApiArg = {
  id: string;
};
export type DeleteLeadListControllerDeleteLeadListApiResponse =
  /** status 200  */ DeleteLeadListResponseDto | /** status 204  */ void;
export type DeleteLeadListControllerDeleteLeadListApiArg = {
  id: string;
};
export type FinishingCampaignControllerFinishingCampaignsApiResponse =
  /** status 201  */ CampaignRunDto[];
export type FinishingCampaignControllerFinishingCampaignsApiArg = void;
export type ForceRunRecipientsControllerForceRunRecipientsApiResponse =
  /** status 201  */ ForceRunRecipientsResponseDto[];
export type ForceRunRecipientsControllerForceRunRecipientsApiArg = {
  forceRunRecipientsRequestDto: ForceRunRecipientsRequestDto;
};
export type GetAllCampaignsControllerGetAllCampaignsApiResponse =
  /** status 200  */ CampaignWithDetailedInfoDto[];
export type GetAllCampaignsControllerGetAllCampaignsApiArg = void;
export type GetCampaignRunControllerGetCampaignApiResponse = /** status 200  */ CampaignRunDto;
export type GetCampaignRunControllerGetCampaignApiArg = {
  id: string;
};
export type GetMedianCallDurationControllerGetCampaignApiResponse =
  /** status 200  */ MedianCallDurationDto;
export type GetMedianCallDurationControllerGetCampaignApiArg = {
  id: string;
};
export type GetCallsReportControllerGetCallsReportApiResponse = unknown;
export type GetCallsReportControllerGetCallsReportApiArg = {
  campaignId?: string;
  companyId?: string;
  start?: string;
  end?: string;
};
export type GetRecipientConversationControllerGetCampaignApiResponse =
  /** status 200  */ RecipientConversationDto[];
export type GetRecipientConversationControllerGetCampaignApiArg = {
  id: string;
  statuses?: string;
  sortBy?: string;
  sortOrder?: string;
};
export type PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiArg = {
  id: string;
  /** Conversation type */
  'conversation-type'?: 'outbound-campaign-call' | 'test-call';
  partialUpdateRecipientConversationRequestDto: PartialUpdateRecipientConversationRequestDto;
};
export type ProcessCampaignControllerProcessCampaignApiResponse =
  /** status 201  */ RecipientConversationDto[];
export type ProcessCampaignControllerProcessCampaignApiArg = void;
export type ProcessFollowUpControllerProcessCampaignsApiResponse = /** status 201  */ string[];
export type ProcessFollowUpControllerProcessCampaignsApiArg = void;
export type ProcessMessageResultControllerProcessMessageResultApiResponse =
  /** status 201  */ string;
export type ProcessMessageResultControllerProcessMessageResultApiArg = {
  token: string;
  processMessageResultRequestDto: ProcessMessageResultRequestDto;
};
export type StopCampaignRunControllerStopCampaignRunApiResponse = /** status 201  */ CampaignRunDto;
export type StopCampaignRunControllerStopCampaignRunApiArg = {
  id: string;
};
export type TerminateConversationControllerTerminateConversationApiResponse =
  /** status 201  */ RecipientConversationDto;
export type TerminateConversationControllerTerminateConversationApiArg = {
  id: string;
};
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse =
  /** status 200  */ UpdateLeadListOrderResponseDto;
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiArg = {
  updateLeadListOrderRequestDto: UpdateLeadListOrderRequestDto;
};
export type GetAgentControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentControllerGetAgentApiArg = {
  id: string;
};
export type EditAgentControllerEditAgentApiResponse = /** status 200  */ AgentDto;
export type EditAgentControllerEditAgentApiArg = {
  id: string;
  editAgentRequestDto: EditAgentRequestDto;
};
export type DeleteAgentControllerDeleteAgentApiResponse = /** status 200  */ BaseOkResponseDto;
export type DeleteAgentControllerDeleteAgentApiArg = {
  id: string;
};
export type GetAgentSystemControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentSystemControllerGetAgentApiArg = {
  id: string;
};
export type GetAllAgentsControllerGetAllAgentsApiResponse = /** status 200  */ AgentDto[];
export type GetAllAgentsControllerGetAllAgentsApiArg = void;
export type CreateAgentControllerCreateAgentApiResponse = /** status 201  */ AgentDto;
export type CreateAgentControllerCreateAgentApiArg = {
  createAgentRequestDto: CreateAgentRequestDto;
};
export type GetAllVoicesControllerGetAllVoicesApiResponse = /** status 200  */ VoiceDto[];
export type GetAllVoicesControllerGetAllVoicesApiArg = void;
export type GetUsageControllerGetUsageApiResponse = /** status 200  */ GetUsageResponseDto;
export type GetUsageControllerGetUsageApiArg = void;
export type GetVoiceControllerGetVoiceApiResponse = /** status 200  */ VoiceDto;
export type GetVoiceControllerGetVoiceApiArg = {
  id: string;
};
export type EditVoiceControllerEditVoiceApiResponse = /** status 200  */ VoiceDto;
export type EditVoiceControllerEditVoiceApiArg = {
  id: string;
  voiceRequestDto: VoiceRequestDto;
};
export type CreateVoiceControllerCreateVoiceApiResponse = /** status 201  */ VoiceDto;
export type CreateVoiceControllerCreateVoiceApiArg = {
  voiceRequestDto: VoiceRequestDto;
};
export type DeleteVoiceControllerDeleteVoiceApiResponse = /** status 200  */ BaseOkResponseDto;
export type DeleteVoiceControllerDeleteVoiceApiArg = {
  id: string;
};
export type CreateCompanyControllerCreateCompanyApiResponse = /** status 201  */ CompanyDto;
export type CreateCompanyControllerCreateCompanyApiArg = {
  createCompanyRequestDto: CreateCompanyRequestDto;
};
export type GetAllCompaniesControllerGetAllCompaniesApiResponse = /** status 200  */ CompanyDto[];
export type GetAllCompaniesControllerGetAllCompaniesApiArg = void;
export type GetCompanyControllerGetCompanyApiResponse = /** status 200  */ CompanyDto;
export type GetCompanyControllerGetCompanyApiArg = {
  id: string;
};
export type EditCompanyControllerEditCompanyApiResponse = /** status 200  */ CompanyDto;
export type EditCompanyControllerEditCompanyApiArg = {
  id: string;
  editCompanyRequestDto: EditCompanyRequestDto;
};
export type HealthControllerCheckApiResponse = /** status 200 The Health Check is successful */ {
  status?: string;
  info?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  } | null;
  error?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  } | null;
  details?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  };
};
export type HealthControllerCheckApiArg = void;
export type TextToSpeechControllerCreateCampaignApiResponse = unknown;
export type TextToSpeechControllerCreateCampaignApiArg = {
  enhance?: boolean;
  debug?: boolean;
  textToSpeechRequestDto: TextToSpeechRequestDto;
};
export type TokenResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type SignInRequestDto = {
  login: string;
  password: string;
};
export type AdminSignInResponseDto = {
  accessToken: string;
};
export type AdminSignInRequestDto = {
  login: string;
  password: string;
};
export type ForgotPasswordResponseDto = {
  message: string;
};
export type ForgotPasswordRequestDto = {
  email: string;
};
export type ResetPasswordResponseDto = {
  message: string;
};
export type ResetPasswordRequestDto = {
  token: string;
  password: string;
};
export type UserRoles = 'admin';
export type UserDto = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRoles;
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  companyId: string;
};
export type EditUserRequestDto = {
  firstName: string;
  lastName: string;
  password?: string;
};
export type AdminRoles = 'manager';
export type AdminDto = {
  id: string;
  email: string;
  role: AdminRoles;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type ConversationRoomMetadataDto = {
  type: 'outbound-campaign-call' | 'test-call';
  companyId: string;
  campaignId: string;
  conversationId: string;
  agentId: string;
  timezone: string;
};
export type ConversationInfoDto = {
  id: string;
  creationTime: string;
  roomName: string;
  metadata: ConversationRoomMetadataDto;
};
export type OutboundTestCallToWebV2ResponseDto = {
  ok: boolean;
  callInfo: ConversationInfoDto;
  roomAccessToken: string;
};
export type OutboundTestCallToWebV2RequestDto = {
  agentId: string;
};
export type BaseOkResponseDto = {
  ok: boolean;
};
export type OutboundTestCallToPhoneRequestDto = {
  agentId: string;
  calleePhone: string;
  trunkName?: string | null;
};
export type StartConversationRecordResponseDto = {
  egressId: string;
  roomId: string;
  roomName: string;
};
export type StartConversationRecordRequestDto = {
  roomName: string;
};
export type LoadTestAgentsResponseDto = {
  ok: boolean;
  agentsInfoRan: ConversationInfoDto[];
};
export type LoadTestAgentsRequestDto = {
  numberOfAgents?: number;
  agentId: string;
};
export type OutboundMultipleCallsToPhoneRequestDto = {
  agentId: string;
  calleePhones: string[];
  trunkName?: string | null;
};
export type CampaignLeadDto = {
  phone: string;
  id: string;
  name: string | null;
  email: string | null;
  leadListId: string;
  createdAt: string;
  updatedAt: string;
};
export type CampaignLeadListDto = {
  name: string;
  order: number;
  campaignId: string;
  leads: CampaignLeadDto[];
  createdAt: string;
  updatedAt: string;
  id: string;
};
export type CallScheduleDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CampaignRunStatusType = 'active' | 'inactive' | 'finishing';
export type CampaignRunRecipientStatusType =
  | 'pending'
  | 'processing'
  | 'calling'
  | 'completed'
  | 'failed'
  | 'terminated';
export type RecipientInteractionResultType =
  | 'follow_up_completed'
  | 'follow_up_required'
  | 'follow_up_processing'
  | 'follow_up_failed'
  | 'callback_requested'
  | 'callback_completed'
  | 'do_not_call';
export type RecipientConversationSentimentType = 'positive' | 'negative' | 'neutral';
export type SentimentScoreDto = {
  label: RecipientConversationSentimentType;
  score: number;
};
export type RecipientConversationSentimentDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
  id: string;
  recipientConversationId: string;
  updatedAt: string;
};
export type RecipientConversationDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: CampaignRunRecipientStatusType;
  interactionResult?: RecipientInteractionResultType;
  leadId: string;
  campaignRunId: string;
  failReason?: string;
  startedAt?: string;
  answeredAt?: string;
  terminatedAt?: string;
  callbackAt?: string;
  lead: CampaignLeadDto;
  sentiments?: RecipientConversationSentimentDto[];
};
export type CampaignRunDto = {
  id: string;
  status: CampaignRunStatusType;
  startTime: string;
  endTime?: string;
  campaignId: string;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  recipientConversations?: RecipientConversationDto;
};
export type CampaignDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  companyId: string;
  leadList: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto | null;
};
export type LeadSimpleDto = {
  phone: string;
};
export type LeadListSimpleDto = {
  name: string;
  leads: LeadSimpleDto[];
};
export type CallScheduleRequestDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CreateCampaignRequestDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  leadList: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type RecipientConversationSentimentRequestDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
};
export type EditCampaignRequestDto = {
  title?: string;
  description?: string;
  retryIntervalInMinutes?: number;
  retryMaxNumber?: number;
  retriesPerDay?: number;
  followUpTemplate?: string | null;
  agentId?: string;
  createdAt?: string;
  updatedAt?: string;
  leadList?: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type DeleteLeadListResponseDto = {
  deletedLeadsCount: number;
  leadListDeleted: boolean;
};
export type ForceRunRecipientsResponseDto = {
  ok: boolean;
  companyId: string;
  timezone: string;
  campaignId: string;
  conversationId: string;
  roomName: string;
  phone: string;
};
export type RecipientIdWithPriorityDto = {
  /** Conversation ID that will be forcibly pushed to the message broker queue. */
  recipientConversationId: string;
  priority?: number | null;
};
export type RoutingKeys = 'eu' | 'uae';
export type ForceRunRecipientsRequestDto = {
  recipients: RecipientIdWithPriorityDto[];
  region: RoutingKeys;
  trunkName?: string | null;
};
export type CampaignWithDetailedInfoDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  companyId: string;
  leadList: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto | null;
  pendingLeadsCount: number;
  reachedLeadsCount: number;
  unreachedLeadsCount: number;
  processingLeadCounts: number;
  ongoingCallCount: number;
  plannedRecallCount: number;
  activeRunsCount: number;
  totalLeads: number;
};
export type MedianCallDurationDto = {
  medianCallDuration: number | null;
};
export type PartialUpdateRecipientConversationRequestDto = {
  status: CampaignRunRecipientStatusType;
  interactionResult?: RecipientInteractionResultType;
  failReason?: string;
  callbackAt?: string;
};
export type DeviceSimpleDto = {
  id: string;
};
export type ContactSimpleDto = {
  wid: string;
};
export type ChatSimpleDto = {
  contact: ContactSimpleDto;
};
export type DataSimpleDto = {
  chat: ChatSimpleDto;
};
export type ProcessMessageResultRequestDto = {
  id: string;
  event: string;
  device: DeviceSimpleDto;
  data: DataSimpleDto;
};
export type OrderItemDto = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderResponseDto = {
  changedOrder: OrderItemDto[];
};
export type ListOrderUpdate = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderRequestDto = {
  updates: ListOrderUpdate[];
};
export type AgentModels = 'gpt-4o' | 'gpt-4-turbo' | 'gpt-4' | 'gpt-3.5-turbo' | 'gpt-4o-mini';
export type AgentProfileType =
  | 'FraudPreventor'
  | 'SalesRepresentative'
  | 'SupportAndRetentionSpecialist'
  | 'Collector';
export type VoiceDto = {
  id: string;
  voiceId: string;
  name: string;
  previewUrl: string;
  gender?: string;
  accent?: string;
  /** Voice json settings */
  voiceSettings?: object;
  createdAt: string;
  updatedAt: string;
};
export type MissionDto = {
  id: string;
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
  createdAt: string;
  updatedAt: string;
};
export type AgentDto = {
  id: string;
  name: string;
  model: AgentModels;
  profile?: AgentProfileType;
  voiceId: string;
  companyId: string;
  missionId: string;
  langfusePromptId: string;
  createdAt: string;
  updatedAt: string;
  voice: VoiceDto;
  mission: MissionDto;
};
export type EditMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type EditAgentRequestDto = {
  name: string;
  profile?: AgentProfileType;
  model: AgentModels;
  voiceId: string;
  mission: EditMissionRequestDto;
};
export type CreateMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type CreateAgentRequestDto = {
  name: string;
  profile?: AgentProfileType;
  model: AgentModels;
  voiceId: string;
  mission: CreateMissionRequestDto;
};
export type PeriodDto = {
  start: string;
  end: string;
};
export type AiUsageDto = {
  organizationId: string;
  organizationName: string;
  snapshotId: string;
  operation: string;
  totalRequests: number;
  totalContextTokens: number;
  totalGeneratedTokens: number;
  totalCachedContextTokens: number;
  projectId: ((string | null) | (null | null)) | null;
};
export type AiUsageResultDto = {
  period: PeriodDto;
  data: AiUsageDto[];
};
export type TtsUsageDto = {
  characterCount: number;
  characterLimit: number;
};
export type TtsUsageResultDto = {
  period: PeriodDto;
  data: TtsUsageDto;
};
export type SttUsageDto = {
  projectName: string;
  projectId: string;
  hours: number;
  totalHours: number;
};
export type SttUsageResultDto = {
  period: PeriodDto;
  data: SttUsageDto[];
};
export type GetUsageResponseDto = {
  ai: AiUsageResultDto;
  tts: TtsUsageResultDto;
  stt: SttUsageResultDto;
};
export type VoiceRequestDto = {
  voiceId: string;
  name: string;
  previewUrl: string;
  gender?: string;
  accent?: string;
  /** Voice json settings */
  voiceSettings?: object;
};
export type CompanyDto = {
  id: string;
  name: string;
  logo?: string | null;
  description?: string | null;
  industryType?: string | null;
  address?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  agents?: AgentDto[];
  users?: UserDto[];
};
export type UserRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: UserRoles;
};
export type CreateCompanyRequestDto = {
  name: string;
  description?: string | null;
  logo?: string | null;
  users: UserRequestDto[];
};
export type EditCompanyRequestDto = {
  name: string;
  description: string | null;
  logo: string | null;
};
export type ExternalVoiceSettingsDto = {
  /** Stability level of the voice */
  stability: number;
  /** Similarity boost for the voice */
  similarityBoost: number;
  /** Style intensity for the voice */
  style: number;
  /** Use speaker boost or not */
  useSpeakerBoost: boolean;
  /** External voice ID used to reference voices in a third-party service. */
  externalVoiceId: string;
};
export type TextToSpeechRequestDto = {
  text: string;
  /** Optional voice id for the text-to-speech conversion */
  voiceId?: string;
  /** Optional voice settings for customization */
  voiceSettings?: ExternalVoiceSettingsDto;
  tempPayload?: string;
};
export const {
  useAppControllerGetHelloQuery,
  useMetricsControllerWebhookQuery,
  useRefreshTokensControllerRefreshTokenUserMutation,
  useRefreshTokensControllerRefreshTokenAdminMutation,
  useSignInControllerSignInMutation,
  useAdminSignInControllerSignInMutation,
  useForgotPasswordControllerForgotPasswordMutation,
  useResetPasswordControllerResetPasswordMutation,
  useUserMeControllerUserMeQuery,
  useGetUsersListControllerGetUsersListQuery,
  useEditUserControllerEditUserMutation,
  useAdminMeControllerAdminMeQuery,
  useOutboundTestCallToWebControllerUserMeMutation,
  useHangupTestCallOnWebControllerHangupMutation,
  useOutboundTestCallToPhoneControllerOutboundCallMutation,
  useGetOutboundTestCallToPhoneInfoControllerGetInfoQuery,
  useStartConversationRecordControllerStartConversationRecordMutation,
  useHangupTestCallOnPhoneControllerUserMeMutation,
  useLoadTestAgentsControllerLoadTestAgentsMutation,
  useOutboundMultipleCallsToPhoneControllerOutboundCallsMutation,
  useCreateCampaignControllerCreateCampaignMutation,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useCreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentMutation,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useEditCampaignControllerEditCampaignMutation,
  useGetCampaignControllerGetCampaignQuery,
  useDeleteLeadListControllerDeleteLeadListMutation,
  useFinishingCampaignControllerFinishingCampaignsMutation,
  useForceRunRecipientsControllerForceRunRecipientsMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
  useGetCampaignRunControllerGetCampaignQuery,
  useGetMedianCallDurationControllerGetCampaignQuery,
  useGetCallsReportControllerGetCallsReportQuery,
  useGetRecipientConversationControllerGetCampaignQuery,
  usePartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationMutation,
  useProcessCampaignControllerProcessCampaignMutation,
  useProcessFollowUpControllerProcessCampaignsMutation,
  useProcessMessageResultControllerProcessMessageResultMutation,
  useStopCampaignRunControllerStopCampaignRunMutation,
  useTerminateConversationControllerTerminateConversationMutation,
  useUpdateLeadListOrderControllerUpdateLeadListOrderMutation,
  useGetAgentControllerGetAgentQuery,
  useEditAgentControllerEditAgentMutation,
  useDeleteAgentControllerDeleteAgentMutation,
  useGetAgentSystemControllerGetAgentQuery,
  useGetAllAgentsControllerGetAllAgentsQuery,
  useCreateAgentControllerCreateAgentMutation,
  useGetAllVoicesControllerGetAllVoicesQuery,
  useGetUsageControllerGetUsageQuery,
  useGetVoiceControllerGetVoiceQuery,
  useEditVoiceControllerEditVoiceMutation,
  useCreateVoiceControllerCreateVoiceMutation,
  useDeleteVoiceControllerDeleteVoiceMutation,
  useCreateCompanyControllerCreateCompanyMutation,
  useGetAllCompaniesControllerGetAllCompaniesQuery,
  useGetCompanyControllerGetCompanyQuery,
  useEditCompanyControllerEditCompanyMutation,
  useHealthControllerCheckQuery,
  useTextToSpeechControllerCreateCampaignMutation,
} = injectedRtkApi;
