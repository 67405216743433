import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, Stack, TextField, Box, Grid, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateCompanyControllerCreateCompanyMutation } from '@shared/services/apiService/apiService';
import { useStyles } from '../styles';
import UserCard from './UserCard';
import useTokenSwitcher from '../hooks/useTokenSwticher';
import ActionButtons from './ActionsButtons';

export interface UserFormData {
  userLogin: string;
  temporaryPassword: string;
}

export interface CreateCompanyForm {
  companyName: string;
  description: string;
  maxParallelCalls: number;
  users: UserFormData[];
}

const CompanyForm: React.FC<{ closeDialog: () => void }> = ({ closeDialog }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm<CreateCompanyForm>({
    defaultValues: {
      companyName: '',
      description: '',
      maxParallelCalls: 10,
      users: [{ userLogin: '', temporaryPassword: '' }],
    },
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const [createCompany] = useCreateCompanyControllerCreateCompanyMutation();
  const { switchToken } = useTokenSwitcher();

  const onSubmit = async (data: CreateCompanyForm) => {
    setErrorMessage(null);

    const superAdminAccessToken = localStorage.getItem('superAdminAccessToken') || '';

    const isValid = await trigger();

    if (isValid) {
      await switchToken(superAdminAccessToken, async () => {
        try {
          await createCompany({
            createCompanyRequestDto: {
              name: data.companyName,
              description: data.description,
              users: data.users.map((user) => ({
                email: user.userLogin,
                password: user.temporaryPassword,
                firstName: '',
                lastName: '',
                role: 'admin',
              })),
            },
          }).unwrap();

          closeDialog();
          reset();
        } catch (error) {
          console.error('Submission failed:', error);
          const message = (error as { data: { message?: string } }).data.message;
          setErrorMessage(message || JSON.stringify((error as { data: any }).data));
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: t('superAdminMainPage.companies.errors.companyNameError'),
                maxLength: {
                  value: 256,
                  message: t('superAdminMainPage.companies.errors.companyNameError'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('superAdminMainPage.companies.company.name')}
                  variant="outlined"
                  fullWidth
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 256,
                  message: t('superAdminMainPage.companies.errors.descriptionError'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('superAdminMainPage.companies.company.description')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="maxParallelCalls"
              control={control}
              rules={{
                required: t('superAdminMainPage.companies.errors.maxParallelCallsError'),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t('superAdminMainPage.companies.errors.maxParallelCallsError'),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('superAdminMainPage.companies.company.maxParallelCalls')}
                  variant="outlined"
                  fullWidth
                  type="number"
                  error={!!errors.maxParallelCalls}
                  helperText={errors.maxParallelCalls?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.userCard}>
              <Stack spacing={2} width="100%">
                {fields.map((field, index) => (
                  <UserCard
                    key={field.id}
                    index={index}
                    control={control}
                    errors={errors}
                    remove={remove}
                  />
                ))}
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => append({ userLogin: '', temporaryPassword: '' })}
                  className={classes.addButton}
                  disabled={fields.length >= 3}>
                  {t('superAdminMainPage.companies.operation.addUser')}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        {errorMessage && (
          <Typography color="error" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        <ActionButtons closeDialog={closeDialog} />
      </CardContent>
    </form>
  );
};

export default CompanyForm;
