import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  AgentDto,
  useCreateAgentControllerCreateAgentMutation,
  useEditAgentControllerEditAgentMutation,
} from '@shared/services/apiService/apiService';
import { CreateAgentFormValues } from '../model/types';
import { ErrorData, ResponseMsg } from '@shared/types/admin/agent';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedPath } from '@shared/constants/routes.constants';

export const useCreateAgent = () => {
  const [useCreateAgent, { isLoading: isSaveLoading }] =
    useCreateAgentControllerCreateAgentMutation();
  const [useEditAgent, { isLoading: isEditLoading }] = useEditAgentControllerEditAgentMutation();
  const isLoading = isSaveLoading || isEditLoading;
  const navigate = useNavigate();

  const [agent, setAgent] = useState<AgentDto>({
    id: '',
    name: '',
    model: 'gpt-4o',
    voiceId: '',
    companyId: '',
    missionId: '',
    createdAt: '',
    updatedAt: '',
    voice: {
      id: '',
      voiceId: '',
      name: '',
      previewUrl: '',
      createdAt: '',
      updatedAt: '',
    },
    mission: {
      id: '',
      humanName: '',
      intro: '',
      goal: '',
      offerDetails: '',
      farewell: '',
      createdAt: '',
      updatedAt: '',
    },
    langfusePromptId: '',
  });

  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });

  const { handleSubmit, setValue, reset, ...formMethods } = useForm<CreateAgentFormValues>({
    mode: 'all',
    defaultValues: {
      id: '',
      name: '',
      model: 'gpt-4o',
      voiceId: '',
      mission: {
        humanName: '',
        prompt: '',
      },
    },
  });

  function handleErrResponse(error: FetchBaseQueryError | SerializedError) {
    if ('data' in error && error.data) {
      const errorData = error.data as ErrorData;
      setResponseMsg({
        message: errorData.message,
        type: 'error',
      });
      return;
    }
  }

  const handleLocationAdminAgent = () => {
    navigate(AuthenticatedPath.AGENT);
  };

  const onSubmit = handleSubmit(async (data) => {
    setResponseMsg({
      message: '',
      type: 'success',
    });

    try {
      let response;
      if (data.id) {
        response = await useEditAgent({
          id: data.id,
          editAgentRequestDto: {
            name: data.name,
            model: data.model ? data.model : 'gpt-4o',
            voiceId: data.voiceId,
            profile: data.profile,
            mission: {
              humanName: data.mission.humanName,
              intro: data.mission.intro ? data.mission.intro : '',
              goal: '',
              offerDetails: '',
              farewell: '',
            },
          },
        });
      } else {
        response = await useCreateAgent({
          createAgentRequestDto: {
            name: data.name,
            model: data.model ? data.model : 'gpt-4o',
            voiceId: data.voiceId,
            profile: data.profile,
            mission: {
              humanName: data.mission.humanName,
              intro: data.mission.intro ? data.mission.intro : '',
              goal: '',
              offerDetails: '',
              farewell: '',
            },
          },
        });
      }
      if ('error' in response) {
        const { error } = response;
        if (error) {
          handleErrResponse(error);
        }
      } else if ('data' in response) {
        setAgent(response.data);
        setResponseMsg({
          message: data.id ? 'Successfully Edited' : 'Successfully Saved',
          type: 'success',
        });
        handleLocationAdminAgent();
      }
    } catch (error) {
      console.log('Creating agent failed:', error);
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue,
    reset,
    onSubmit,
    responseMsg,
    agent,
    isLoading,
    ...formMethods,
  };
};
