import { AgentProfileType, AgentModels } from '@shared/services/apiService/apiService';

export type CreateAgentFormValues = {
  id?: string;
  name: string;
  model: AgentModels;
  profile?: AgentProfileType;
  voiceId: string;
  personality: string;
  mission: {
    humanName: string;
    prompt: string;
    intro: string;
    goal: string;
    offerDetails: string;
    farewell: string;
  };
};

export const agentProfileTypes: AgentProfileType[] = [
  'FraudPreventor',
  'SalesRepresentative',
  'SupportAndRetentionSpecialist',
  'Collector',
];
