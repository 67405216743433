import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material';
import { IconPlayerPlay, IconPlayerStop } from '@tabler/icons-react';

import { useCreateAgent } from '@features/Admin/Agent/hooks/useCreateAgent';

import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import ParentCard from '@shared/components/shared/ParentCard';
import BlankCard from '@shared/components/shared/BlankCard';
import { AuthenticatedPath } from '@shared/constants/routes.constants';

import { TestingAgentLivekitPopup } from '@widgets/Popup/TestingAgentLivekitPopup/TestingAgentLivekitPopup';
import CustomAlert from '@shared/components/alert/alert';
import Spinner from '@shared/components/spinner/Spinner';
import {
  AgentDto,
  VoiceDto,
  useGetAgentControllerGetAgentQuery,
  useGetAllVoicesControllerGetAllVoicesQuery,
} from '@shared/services/apiService/apiService';
import { FormFields } from '@shared/types/admin/agent';
import { useAppSelector } from '@shared/store';
import { agentProfileTypes } from '@features/Admin/Agent/model/types';

export const CreateAgentPage = () => {
  const {
    onSubmit,
    setValue,
    reset,
    control,
    isLoading,
    responseMsg,
    agent: agentResponse,
    formState: { errors },
  } = useCreateAgent();

  const { t } = useTranslation();
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const BCrumb = [
    {
      to: AuthenticatedPath.AGENT,
      title: t('agentPage.header.agent'),
    },
    {
      title: location.pathname.includes('new') ? t('action.create') : t('action.edit'),
    },
  ];

  const [isLoadingFlag, setIsLoadingFlag] = useState<boolean>(false);
  const [agentId, setAgentId] = useState<string | null>(null);
  const [agent, setAgent] = useState<AgentDto>({
    id: '',
    name: '',
    model: 'gpt-4o',
    voiceId: '',
    companyId: '',
    missionId: '',
    langfusePromptId: '',
    createdAt: '',
    updatedAt: '',
    voice: {
      id: '',
      voiceId: '',
      name: '',
      previewUrl: '',
      createdAt: '',
      updatedAt: '',
    },
    mission: {
      id: '',
      humanName: '',
      intro: '',
      goal: '',
      offerDetails: '',
      farewell: '',
      createdAt: '',
      updatedAt: '',
    },
  });
  const [voiceList, setVoiceList] = useState<VoiceDto[]>();
  const [profileTypeList, setProfileTypeList] = useState<{ id: string; label: string }[]>();
  const [saveChangeFlag, setSaveChangeFlag] = useState<boolean>(false);
  const [testAgentFlag, setTestAgentFlag] = useState<boolean>(false);
  const [saveChangeDialogFlag, setSaveChangeDialogFlag] = useState<boolean>(false);
  const [isOpenTestAgentLivekit, setIsOpenTestAgentLivekit] = useState<boolean>(false);
  const [isPlayingVoice, setIsPlayingVoice] = useState<boolean>(false);
  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  const { data: agentData, refetch } = useGetAgentControllerGetAgentQuery(
    { id: agentId! },
    { skip: !agentId, refetchOnMountOrArgChange: true },
  );

  const { data: voiceData, isLoading: isVoiceLoading } =
    useGetAllVoicesControllerGetAllVoicesQuery();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setAgentId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (voiceData) {
      setVoiceList(voiceData);
    } else if (agentData) {
      setAgent(agentData);
    }
  }, [voiceData, agentData]);

  useEffect(() => {
    setProfileTypeList(
      agentProfileTypes.map((type) => {
        return { id: type, label: t(`agentPage.agentProfileTypes.${type}`) };
      }),
    );
  }, []);

  useEffect(() => {
    if (agentId) {
      setIsLoadingFlag(true);
      refetch().then((res) => {
        if (res.data) {
          setAgent(res.data);
          setTestAgentFlag(true);
          setIsLoadingFlag(false);
        } else {
          setTestAgentFlag(false);
          setIsLoadingFlag(false);
        }
      });
    }
  }, [agentId]);

  useEffect(() => {
    if (agentResponse.id) {
      navigator(AuthenticatedPath.EDIT_AGENT + `?id=${agentResponse.id}`);
    }
  }, [agentResponse]);

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValue(name as FormFields, value);

    const names = name.split('.');
    names.length > 1
      ? setAgent((prevValues) => ({
          ...prevValues,
          mission: { ...prevValues.mission, [names[1]]: value },
        }))
      : setAgent((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (agentId) {
      reset({
        id: agentId,
        name: agent.name,
        voiceId: agent.voiceId,
        profile: agent.profile,
        mission: {
          humanName: agent.mission?.humanName,
          intro: agent.mission?.intro,
          goal: agent.mission?.goal,
          offerDetails: agent.mission?.offerDetails,
          farewell: agent.mission?.farewell,
        },
      });
    }
    onSubmit();
    setTestAgentFlag(true);
    setSaveChangeFlag(false);
  };

  const handleCancel = () => {
    if (saveChangeFlag == true) {
      setSaveChangeDialogFlag(true);
      return;
    }
    navigator(AuthenticatedPath.AGENT);
  };

  const handleSaveChange = () => {
    submitButtonRef.current?.click();
    setSaveChangeDialogFlag(false);
    setSaveChangeFlag(false);
    navigator(AuthenticatedPath.AGENT);
  };

  const toggleTestAgentLivekit = useCallback(() => {
    if (isOpenTestAgentLivekit) {
      return setIsOpenTestAgentLivekit(false);
    }

    setIsOpenTestAgentLivekit(true);
  }, [isOpenTestAgentLivekit]);

  const handleCloseTestAgentModal = () => {
    setIsOpenTestAgentLivekit(false);
  };

  let audio = new Audio();
  const handelTestVoice = (voiceId: string) => {
    const voiceItem = voiceList?.find((item) => item.id === voiceId);

    if (voiceItem) {
      audio.pause();
      audio = new Audio(voiceItem.previewUrl);
      audio.play();

      setIsPlayingVoice(true);

      audio.onended = () => {
        setIsPlayingVoice(false);
      };
    } else {
      console.log(`Voice with id ${voiceId} not found.`);
    }
  };

  if (isLoadingFlag || isVoiceLoading || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer title="Create Agent" description="Create agent page">
        <Breadcrumb
          title={t('agentPage.header.agentName', { agentName: currentCompany?.title })}
          items={BCrumb}
        />
        <form onSubmit={handleSubmit}>
          <BlankCard>
            <Grid container spacing={3} sx={{ padding: '30px' }}>
              <Grid item xs={12}>
                <ParentCard title={t('agentPage.agentDetails')}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="end">
                      <CustomFormLabel
                        control={control}
                        htmlFor="agent-name"
                        sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                        {t('agentPage.agentName')}:
                      </CustomFormLabel>
                    </Grid>
                    <Grid item container xs={12} sm={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <CustomTextField
                            control={control}
                            name="name"
                            value={agent?.name}
                            onChange={handleOnChangeInput}
                            id="agent-name"
                            type="text"
                            varient="outlined"
                            inputProps={{ maxLength: '56' }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={6} justifyContent="end" display="flex">
                          <Button
                            className="left-padding-10px"
                            onClick={toggleTestAgentLivekit}
                            sx={{ height: 44, ml: 2 }}
                            disabled={!testAgentFlag}
                            startIcon={<IconPlayerPlay />}>
                            {t('agentPage.testAgent')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="end">
                      <CustomFormLabel
                        name="voice"
                        htmlFor="agent-voice"
                        sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                        {t('agentPage.voiceLanguage')}:
                      </CustomFormLabel>
                    </Grid>

                    <Grid item container xs={12} sm={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name="voiceId"
                            render={() => (
                              <CustomSelect
                                name="voiceId"
                                onChange={handleOnChangeInput}
                                value={agent?.voiceId || ''}
                                id="agent-voice"
                                variant="outlined"
                                fullWidth
                                required>
                                {voiceList?.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.id}
                                    sx={{
                                      display: 'block',
                                    }}>
                                    <Box>
                                      {option.name} {option.gender ? `(${option.gender})` : ''}
                                    </Box>
                                    <Box sx={{ fontSize: '13px', fontWeight: '600' }}>
                                      {option.accent}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} justifyContent="end" display="flex">
                          <Button
                            onClick={() => handelTestVoice(agent?.voiceId!)}
                            sx={{ height: 44 }}
                            startIcon={isPlayingVoice ? <IconPlayerStop /> : <IconPlayerPlay />}
                            disabled={!agent?.voiceId || isPlayingVoice}>
                            {t('agentPage.testVoice')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="end">
                      <CustomFormLabel
                        name="profile"
                        htmlFor="agent-profile"
                        sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                        {t('agentPage.profile')}:
                      </CustomFormLabel>
                    </Grid>

                    <Grid item container xs={12} sm={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name="profile"
                            render={() => (
                              <CustomSelect
                                name="profile"
                                onChange={handleOnChangeInput}
                                value={agent?.profile || undefined}
                                id="agent-profile"
                                variant="outlined"
                                fullWidth>
                                {profileTypeList?.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.id}
                                    sx={{
                                      display: 'block',
                                    }}>
                                    <Box sx={{ fontSize: '13px', fontWeight: '600' }}>
                                      {option.label}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ParentCard>
              </Grid>

              <Grid item xs={12}>
                <ParentCard title={t('agentPage.identifyBuilder')}>
                  <Box>
                    <Grid container spacing={3} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="start"
                        justifyContent="end">
                        <CustomFormLabel
                          htmlFor="agent-humanName"
                          sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                          {t('agentPage.agentHumanName')}:
                        </CustomFormLabel>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <CustomTextField
                          control={control}
                          name="mission.humanName"
                          value={agent?.mission.humanName}
                          onChange={handleOnChangeInput}
                          id="agent-humanName"
                          type="text"
                          varient="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid marginTop={1} container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="start"
                        justifyContent="end">
                        <CustomFormLabel
                          htmlFor="agent-prompt"
                          sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                          {t('agentPage.prompt')}:
                        </CustomFormLabel>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <CustomTextField
                          control={control}
                          name="mission.intro"
                          value={agent?.mission.intro}
                          onChange={handleOnChangeInput}
                          id="agent-prompt"
                          type="text"
                          varient="outlined"
                          multiline
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ParentCard>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <Stack direction="row" justifyContent="end" spacing={2}>
                  <Button variant="contained" color="primary" type="submit" ref={submitButtonRef}>
                    {t('agentPage.operation.save')}
                  </Button>
                  <Button onClick={handleCancel} variant="text" color="error">
                    {t('agentPage.operation.cancel')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </BlankCard>
        </form>
      </PageContainer>
      {responseMsg.message ? (
        <CustomAlert message={responseMsg.message} type={responseMsg.type} />
      ) : (
        ''
      )}
      <TestingAgentLivekitPopup
        agentId={agent?.id!}
        showDialogFlag={isOpenTestAgentLivekit}
        closeDialog={handleCloseTestAgentModal}
      />
      <Dialog
        open={saveChangeDialogFlag}
        onClose={() => setSaveChangeDialogFlag(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('dialog.title2')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('dialog.content2')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleSaveChange} color="primary">
            {t('action.saveChange')}
          </Button>
          <Button onClick={() => navigator(AuthenticatedPath.AGENT)} color="info">
            {t('action.closeWithoutSaving')}
          </Button>
          <Button onClick={() => setSaveChangeDialogFlag(false)} color="error" autoFocus>
            {t('action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
